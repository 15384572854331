<template>
  <div class="voice-recognition border">
    <!-- Microphone Icon Button -->
    <button class="microphone-button">
      <i
        v-if="$store.state.heygen.isSpeaking"
        class="fas fa-volume-up listening"
      ></i>
      <!-- @click="interruptTask" -->
      <i
        v-else-if="$store.state.heygen.isProcessing"
        class="fas fa-spinner fa-spin"
      ></i>
      <div
        class="volume-ripple"
        v-else-if="$store.state.heygen.isListening"
      >
        <div></div>
        <div></div>
      </div>
      <i
        v-else-if="
          !$store.state.heygen.isListening && !$store.state.heygen.isProcessing
        "
        class="fas fa-microphone text-primary"
      ></i>
    </button>
  </div>
</template>

<script>
import { WEB_SPEED_GOOGLE_LANGUAGES } from "@/lang";
export default {
  data() {
    return {
      dontSendToAPI: false,
      audioChunks: [],
      audioURL: null,
      transcription: "",
      errorMessage: "",
      mediaRecorder: null,
      audioContext: null,
      analyser: null,
      stream: null, // To keep track of the media stream
      hearSilenceSpeed: 100, // To keep track of the media stream
      silenceThreshold: 8, // Adjust this value based on testing
      silenceDuration: 1800, // 1.8 seconds in milliseconds
      silenceDetected: 0, // Counter to keep track of silence
      silenceChecker: null, // Interval for checking silence
    };
  },
  methods: {
    async interruptTask() {
      const response = await this.$store.dispatch("heygen/interruptTask", {
        session_id: this.$store.state.heygen.sessionInfo?.session_id,
      });
      
      return response;
    },
    async startListening() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.dontSendToAPI = false;
        // Create an audio context and set up the analyser to process audio data
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = this.audioContext.createMediaStreamSource(this.stream);
        this.analyser = this.audioContext.createAnalyser();
        // this.analyser.minDecibels = this.silenceThreshold;
        source.connect(this.analyser);

        this.mediaRecorder = new MediaRecorder(this.stream);
        this.mediaRecorder.start();
        this.audioChunks = [];

        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(this.audioChunks, { type: "audio/mp3" });
          this.audioURL = URL.createObjectURL(audioBlob);
          // Convert the audio blob to base64 and send it to the API
          const base64Audio = await this.blobToBase64(audioBlob);
          // Send audio to the API
          if (this.dontSendToAPI) {
            return;
          }
          await this.sendToSpeechToTextAPI(base64Audio);
        };

        this.startSilenceChecker(); // Start checking for silence
      } catch (err) {
        this.$store.commit("heygen/setIsListening", false);
        this.errorMessage = "Error accessing microphone: " + err.message;
        alert(this.errorMessage);
      }
    },
    eventStopListening() {
      this.$store.commit("heygen/resetState");
      this.stopListening();
    },
    stopListening() {
      this.stopStream(); // Ensure the stream is stopped
      this.mediaRecorder?.stop();
      this.audioContext?.close();
      this.dontSendToAPI = true;
      this.stopSilenceChecker(); // Stop checking for silence
    },
    stopStream() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
        this.stream = null;
      }
    },
    async blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async sendToSpeechToTextAPI(base64Audio) {
      const formData = new FormData();
      formData.append("audio", base64Audio);
      formData.append(
        "lang",
        WEB_SPEED_GOOGLE_LANGUAGES[this.$store.state.auth.lang]
      );

      this.$store
        .dispatch("expertmind/speedToText", formData)
        .then((response) => {
          this.transcription = response.transcription;
          if (!response.transcription.replace(/\n/g, "")) {
            this.audioChunks = [];
            this.mediaRecorder.start();
            this.$store.commit("heygen/setIsProcessing", false);
            this.startSilenceChecker(); // Start checking for silence
          } else {
            this.$emit("transcript", this.transcription);
          }
        })
        .catch((error) => {
          this.errorMessage =
            "Error during speech-to-text API call: " + error.message;
          this.audioChunks = [];
          this.mediaRecorder.start();
          this.$store.commit("heygen/setIsProcessing", false);
          this.startSilenceChecker(); // Start checking for silence
          alert(this.errorMessage);
        });
    },
    processWhenSilence() {
      this.$store.commit("heygen/setIsProcessing", true);
      // this.$store.commit("heygen/setIsListening", false);
      this.stopSilenceChecker();
      this.mediaRecorder?.stop();
      // this.audioContext.suspend();
    },
    startSilenceChecker() {
      // Start checking for silence
      this.silenceDetected = 0;
      this.silenceChecker = setInterval(() => {
        const bufferLength = this.analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        this.analyser.getByteFrequencyData(dataArray);
     
        const averageVolume = dataArray.reduce((a, b) => a + b) / bufferLength;
        console.log( averageVolume);

        if (averageVolume < this.silenceThreshold) {
          this.silenceDetected++;
          if (
            this.silenceDetected * this.hearSilenceSpeed >=
            this.silenceDuration
          ) {
            this.processWhenSilence();
          }
        } else {
          console.log("Sounded detected ------------->", averageVolume);
          this.silenceDetected = 0; // Reset the counter if no silence is detected
        }
      }, this.hearSilenceSpeed); // Check for silence every 100ms
    },
    stopSilenceChecker() {
      clearInterval(this.silenceChecker);
      this.silenceChecker = null;
    },
  },
  watch: {
    "$store.state.auth.lang": function () {
      this.$store.commit("heygen/setIsProcessing", false);
      this.$store.commit("heygen/setIsSpeaking", false);
    },
    "$store.state.heygen.isSpeaking": function (newVal) {
      if (!newVal && this.$store.state.heygen.isListening) {
        this.audioChunks = [];
        this.mediaRecorder.start();
        this.startSilenceChecker(); // Start checking for silence
      }
    },
    "$store.state.heygen.isListening": function (newVal) {
      if (!newVal) {
        this.stopListening();
        this.$store.commit("heygen/setIsProcessing", false);
        this.$store.commit("heygen/setIsSpeaking", false);
        this.interruptTask();
      } else {
        this.startListening();
      }
    },
  },
};
</script>

<style>
.voice-recognition {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.microphone-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.microphone-button i {
  font-size: 30px;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

.microphone-button .listening {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (max-width: 768px) {
  .microphone-button i {
    font-size: 32px;
  }
}

.volume-ripple,
.volume-ripple div {
  box-sizing: border-box;
  color: red;
}
.volume-ripple {
  display: inline-block;
  position: relative;
  top: -18px;
  left: -18px;
  width: 30px;
  height: 30px;
}
.volume-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: volume-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.volume-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes volume-ripple {
  0% {
    top: 12px;
    left: 12px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 33px;
    left: 33px;
    width: 4px;
    height: 4px;
    opacity: 1;
  }
  100% {
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    opacity: 0;
  }
}
</style>
