export const MULTIPLE_WELCOME_LANGUAGES = {
  en: {
    language: "Language",
    logout: "Logout",
    typeMessage: "Type a message...",
    send: "Send",
    welcome: "Start a message to begin a consultation",
    text:
      "Hello, I am here to assist you with any medical issues you are facing, do not hesitate to talk to me!",
  },
  "zh-TW": {
    language: "語言",
    logout: "登出",
    typeMessage: "輸入訊息...",
    send: "發送",
    welcome: "開始一條消息開始諮詢",
    text: "您好，我將協助您解決任何醫療問題，請隨時與我交談！",
  },
  fr: {
    language: "Langue",
    logout: "Se déconnecter",
    typeMessage: "Tapez un message...",
    send: "Envoyer",
    welcome: "Commencez un message pour commencer une consultation",
    text:
      "Bonjour, je suis là pour vous aider avec tout problème médical que vous rencontrez, n'hésitez pas à me parler!",
  },
  ja: {
    language: "言語",
    logout: "ログアウト",
    typeMessage: "メッセージを入力してください...",
    send: "送信",
    welcome: "相談を開始するにはメッセージを開始します",
    text:
      "こんにちは、私はあなたが直面している医療問題についてお手伝いします、私に話すのをためらわないでください！",
  },
  vi: {
    language: "Ngôn ngữ",
    logout: "Đăng xuất",
    typeMessage: "Nhập tin nhắn...",
    send: "Gửi",
    welcome: "Bắt đầu một tin nhắn để bắt đầu tư vấn",
    text:
      "Xin chào, tôi ở đây để hỗ trợ bạn giải quyết mọi vấn đề y tế mà bạn đang gặp phải, hãy nói cho tôi biết vấn đề của bạn!",
  },
};
export const CONVERT_LANGUAGES = {
  en: "English",
  vi: "Vietnamese",
  "zh-TW": "Traditional Chinese",
  fr: "French",
  ja: "Japanese",
};
export const WEB_SPEED_GOOGLE_LANGUAGES = {
  en: "en-US",
  vi: "vi-VN",
  "zh-TW": "cmn-Hant-TW",
  fr: "fr-FR",
  ja: "ja-JP",
};
export const CONVERT_LANGUAGE_TO_VOICE_HEYGEN = {
  en: "ec4aa6ac882147ffb679176d49f3e41f",
  vi: "c6fb81520dcd42e0a02be231046a8639",
  "zh-TW": "961546a1be64458caa1386ff63dd5d5f",
  fr: "90fc4e27e9e349f196767c0ada520abd",
  ja: "107ec6a82944431ebb6dd0652ecb42d0",
};
