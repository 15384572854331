<template>
  <div
    class="container-fluid d-flex justify-content-center align-items-center active-screen"
    v-if="!$store.state.auth.accessFromLogin"
    @click="reLoginWhenRefresh"
  >
    <div class="text-center">
      <img src="@/assets/clickscreen.png" width="60" alt="" />
      <p class="text-center mt-2">Chat with AI Avatar?</p>
    </div>
  </div>
  <div class="container-fluid" v-else>
    <div
      class="my-3 justify-content-end align-items-center gap-4 d-none d-md-flex"
    >
      <div class="d-flex align-items-center gap-2">
        <div class="fs-6 text-nowrap">
          {{ welcomeText.language }}
        </div>
        <select
          class="form-select"
          aria-label="Select Languages"
          v-model="lang"
          @change="onChangeLang()"
        >
          <option value="en">English</option>
          <option value="vi">Vietnamese</option>
          <option value="zh-TW">Traditional Chinese</option>
          <option value="fr">French</option>
          <option value="ja">Japanese</option>
        </select>
      </div>
      <div class="btn btn-outline-primary" @click="logoutAndRedirect">
        {{ welcomeText.logout }}
      </div>
    </div>
    <div class="d-md-none d-flex justify-content-end align-items-center position-fixed top-0 end-0" style="z-index: 1">
      <div class="">
        <select
          class="form-select  fs-7"
          aria-label="Select Languages"
          v-model="lang"
          @change="onChangeLang()"
        >
          <option value="en">English</option>
          <option value="vi">Vietnamese</option>
          <option value="zh-TW">Traditional Chinese</option>
          <option value="fr">French</option>
          <option value="ja">Japanese</option>
        </select>
      </div>
      <div class="dropdown">
        <div class="dropdown">
          <button
            class="btn btn-outline-default"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fas fa-cog text-black"></i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <div class="dropdown-item">
                <div class="btn btn-outline-primary" @click="logoutAndRedirect">
                  {{ welcomeText.logout }}
                  <i class="fas fa-sign-out"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Left Session: clientAvatar Details at the Top -->
      <div
        class="col-12 col-md-4 bg-white d-flex flex-md-column align-items-center py-1 py-md-3 gap-3 gap-md-0 border-bottom session-avatar"
      >
        <div class="mb-md-3 position-relative video-avatar w-100">
          <DrAvatar :taskInput="drAvatarLastMessage" />
        </div>
        <div class="d-flex flex-column align-items-center w-50">
          <strong>Dr.AI Avatar</strong>
          <div class="fs-6 text-start welcome-text">
            {{ welcomeText.text }}
          </div>
        </div>
      </div>

      <!-- Center Session: Conversation -->
      <div
        class="col-12 col-md-8 d-flex flex-column p-1 bg-white border session-messenger"
      >
        <div
          class="flex-grow-1 p-3 bg-white rounded overflow-auto"
          ref="chatBox"
        >
          <!-- <div v-if="!messages.length" class="text-center fs-7">
            <img
              height="30"
              src="https://www.draiai.com/images/index/kv/logo.svg"
              alt=""
            />
            <br />

            {{ welcomeText.welcome }}
          </div> -->
          <div
            v-for="(message, index) in messages"
            :key="index"
            class="mb-3 d-flex align-items-center gap-2"
            :class="{
              'justify-content-end': message.sender === 'clientAvatar',
              'justify-content-start': message.sender === 'drAvatar',
            }"
          >
            <div v-if="message.sender === 'drAvatar'">
              <img
                height="30"
                src="https://www.draiai.com/images/index/kv/logo.svg"
                alt=""
              />
            </div>
            <div
              class="message-content p-2 rounded"
              :class="{
                'bg-primary text-white': message.sender === 'clientAvatar',
                'bg-light ': message.sender === 'drAvatar',
              }"
            >
              <p class="mb-0" v-html="getTextRaw(message.text)"></p>
              <small
                :class="{
                  'text-white': message.sender === 'clientAvatar',
                  'text-black': message.sender === 'drAvatar',
                }"
                class="d-block fs-7 mt-1 text-end"
                ><i>{{ convertTimeFunc(message.sendAt) }}</i></small
              >
            </div>
            <div v-if="message.sender === 'clientAvatar'">
              <img
                height="30"
                src="https://i.ibb.co/WvW6N4G/ic-user2.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="message-content p-2 rounded bg-light"
            v-if="loadingDrAvatar"
          >
            <!-- Loading response -->
            <div class="lds-ellipsis mb-0">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="position-sticky bottom-0 bg-white">
          <div
            class="d-flex mb-1 mt-3 align-items-center justify-content-center"
          >
            <div class="message-voice">
              <button
                @click="
                  $store.commit(
                    'heygen/setIsListening',
                    !$store.state.heygen.isListening
                  )
                "
              >
                <i
                  class="fas"
                  :class="{
                    'fa-microphone text-primary':
                      !$store.state.heygen.isListening,
                    'fa-file-text text-primary':
                      $store.state.heygen.isListening,
                  }"
                ></i>
              </button>
            </div>
            <div
              class="message-input"
              :class="!$store.state.heygen.isListening ? 'd-none' : ''"
            >
              <ButtonVoice
                @transcript="onTranscriptVoiceMessage($event)"
                :sendMessageTrigger="sendMessageTrigger"
              />
            </div>
            <div
              class="message-input"
              :class="$store.state.heygen.isListening ? 'd-none' : ''"
            >
              <div class="input-group align-items-center gap-2">
                <textarea
                  type="text"
                  class="form-control"
                  ref="messageInput"
                  :placeholder="welcomeText.typeMessage"
                  v-model="newMessage"
                  cols="30"
                  rows="2"
                  auto-grow
                  @keydown.enter.exact.prevent="sendMessage"
                  @keydown.enter.shift.exact.prevent="newMessage += '\n'"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" @click="sendMessage">
                    {{ welcomeText.send }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DrAvatar from "@/components/Livestream/Avatar.vue";
import ButtonVoice from "@/components/Common/ButtonVoice.vue";
import { MULTIPLE_WELCOME_LANGUAGES, CONVERT_LANGUAGES } from "@/lang";
import { convertTime } from "@/utils";
export default {
  name: "dashboard",
  components: {
    DrAvatar,
    ButtonVoice,
  },
  data() {
    return {
      newMessage: "",
      messages: [],
      sendMessageTrigger: false,
      loadingDrAvatar: false,
      drAvatarLastMessage: "",
      welcomeText: {},
      lang: this.$store.state.auth.lang,
    };
  },
  mounted() {
    // window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.welcomeText = MULTIPLE_WELCOME_LANGUAGES[this.lang];
  },
  methods: {
    reLoginWhenRefresh() {
      this.$store.dispatch("auth/reLoginWhenRefresh");
    },
    // handleBeforeUnload() {
    //   this.logout();
    // },
    handleKeyUp(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
    convertTimeFunc(time) {
      return convertTime(time);
    },
    onChangeLang() {
      this.$store.commit("auth/setLang", this.lang);
    },
    getLang() {
      return CONVERT_LANGUAGES[this.lang];
    },
    logoutAndRedirect() {
      this.logout();
      this.$router.push({ name: "index" });
    },
    logout() {
      this.$store.commit("heygen/resetState");
      this.$store.dispatch("auth/logout");
    },
    onTranscriptVoiceMessage(transcript) {
      // this.$refs.messageInput.focus();
      // if (!this.newMessage) {
      //   this.newMessage = transcript.trim();
      //   return;
      // }
      // this.newMessage = this.newMessage + "\n" + transcript;

      if (!transcript) {
        return;
      }
      this.messages.push({
        sender: "clientAvatar", // For now, always clientAvatar. You can toggle between users.
        text: transcript,
        sendAt: new Date().toISOString(),
      });

      this.consultMessage();
    },
    sendMessage() {
      if (this.newMessage.trim()) {
        this.sendMessageTrigger = Math.random();
        this.newMessage = this.newMessage.trim();
        this.messages.push({
          sender: "clientAvatar", // For now, always clientAvatar. You can toggle between users.
          text: this.newMessage,
          sendAt: new Date().toISOString(),
        });

        this.consultMessage();
        this.newMessage = "";
        this.$nextTick(() => {
          this.scrollToBottomChatWindow();
        });
      }
    },
    scrollToBottomChatWindow() {
      const chatBox = this.$refs.chatBox;
      chatBox.scrollTop = chatBox.scrollHeight;
    },
    getTextRaw(text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    consultMessage() {
      this.loadingDrAvatar = true;
      const chatFlag = this.$store.state.expertmind.lastChatFlag;
      const sliceChats = this.messages;
      const lChatQ = sliceChats.filter((msg) => msg.sender === "clientAvatar");
      const lChatA = sliceChats.filter((msg) => msg.sender === "drAvatar");
      const pastChat = lChatA.map((msg, index) => {
        return {
          chatQ: lChatQ[index]?.text,
          chatA: msg?.text,
        };
      });
      const payload = {
        userCDR: this.messages[this.messages.length - 1]?.text,
        chatFlag: chatFlag,
        pastChat: pastChat?.length ? pastChat : null,
        lang: this.$store.state.auth.lang,
        token: localStorage.getItem("token"),
      };
      this.$store
        .dispatch("expertmind/consult", payload)
        .then((response) => {
          this.drAvatarLastMessage = response.token.drAICDR;
          this.messages.push({
            sender: "drAvatar",
            text: response.token.drAICDR,
            sendAt: new Date().toISOString(),
          });
          this.$nextTick(() => {
            this.scrollToBottomChatWindow();
          });
          this.loadingDrAvatar = false;
        })
        .catch(() => {
          this.$store
            .dispatch(
              "auth/dispatchDialogMessage",
              {
                message: "eXpertMind token has been destroy while consulting the message, please LOGIN again",
                type: "alert",
              }
            )
            .then(() => {
              this.$store.commit("heygen/setIsProcessing", false);
              this.loadingDrAvatar = false;
              this.logout();
            });
        });
    },
  },
  watch: {
    lang: function (newVal) {
      this.welcomeText = MULTIPLE_WELCOME_LANGUAGES[newVal];
    },
  },
};
</script>

<style scoped>
.fs-7 {
  font-size: 0.675rem;
}
.status-indicator {
  width: 10px;
  height: 10px;
}

.session-messenger {
  height: calc(100vh - 100px);
}

.message-content {
  max-width: 60%;
}
.client-avatar,
.video-avatar {
  border-radius: 50%; /* Circular border */
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
.message-voice {
  min-width: 15%;
  text-align: center;
}
.message-voice button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.message-voice button i {
  font-size: 42px;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}
.message-input {
  min-width: 85%;
}
.active-screen {
  height: 100vh;
  cursor: pointer;
  animation: pluse 1.5s infinite;
}
@keyframes pluse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@media screen and (max-width: 767px) {
  .session-messenger {
    height: 65vh;
    margin-top: 35vh;
  }
  .session-avatar {
    height: 35vh;
    position: fixed;
    top: 0;
  }
  .welcome-text {
    font-size: 0.775rem !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 1280px) and (orientation: landscape) {
  .session-messenger {
    height: 100vh;
  }
  .session-avatar {
    height: 100vh;
    /* position: sticky;
    top: 0; */
  }
  .welcome-text {
    font-size: 0.675rem !important;
  }
}
</style>
