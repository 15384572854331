<template>
  <div class="text-center">
    <div
      v-if="!mediaCanPlay"
      class="d-flex align-items-center justify-content-center"
    >
      <div class="lds-ripple">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <!-- <img
     v-if="!connected"
      class="rounded-circle mb-5 w-100"
      src="https://www.svgrepo.com/show/382101/male-avatar-boy-face-man-user.svg"
      alt="drAvatar Avatar"
    /> -->

    <video
      v-show="mediaCanPlay"
      id="mediaElement"
      ref="mediaElement"
      playsinline
      autoplay
    ></video>
    <!-- <canvas
      id="canvasElement"
      ref="canvasElement"
      class="videoEle hide"
    ></canvas> -->
  </div>
</template>

<script>
import { CONVERT_LANGUAGE_TO_VOICE_HEYGEN } from "@/lang";

export default {
  props: {
    taskInput: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      sessionInfo: {},
      peerConnection: null,
      connected: false,
      sessionChecker: null,
      sessionKeepAlive: null,
      timeoutSpeaking: null,
      mediaCanPlay: false, // Example value to control media load (update this with real logic)
      // bgColor: "", // Background color for canvas
      // renderID: 0, // For tracking render process
      // backgroundImage:
      //   "url('https://i.ibb.co/2ND390K/modern-hospital-building-illustration-n7pd9krca2gr8qda.png') center / contain no-repeat",
      // replaceColor: [255, 0, 0], // Set the replacement color here (Red in this example)
    };
  },
  mounted() {
    this.createNewSession();
    this.clearAndSetSessionKeepAlive();
    this.sessionChecker = setInterval(async () => {
      if (!this.sessionInfo?.session_id) {
        return;
      }
      await this.listSession();
    }, 5000);
    const mediaElement = this.$refs.mediaElement;
    mediaElement.addEventListener("canplay", () => {
      this.mediaCanPlay = true;
      // this.startRendering();
    });
  },

  methods: {
    async createNewSession() {
      const sessionInfo = await this.newSession();
      if (!sessionInfo) {
        return;
      }
      const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo;
      this.sessionInfo = sessionInfo;
      this.peerConnection = new RTCPeerConnection({ iceServers });
      this.peerConnection.ontrack = (event) => {
        if (event.track.kind === "audio" || event.track.kind === "video") {
          this.connected = true;
          console.log("event?.streams?.[0] --->: ", event?.streams);
          this.$nextTick(() => {
            this.$refs.mediaElement.srcObject = event?.streams?.[0];
          });
        }
      };

      const remoteDescription = new RTCSessionDescription(serverSdp);
      await this.peerConnection.setRemoteDescription(remoteDescription);

      await this.startAndDisplaySession();
    },

    async startAndDisplaySession() {
      if (!this.sessionInfo) {
        return;
      }

      const localDescription = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(localDescription);

      this.peerConnection.onicecandidate = ({ candidate }) => {
        if (candidate) {
          this.handleICE(this.sessionInfo.session_id, candidate.toJSON());
        }
      };

      await this.startSession(this.sessionInfo.session_id, localDescription);
    },
    clearAndSetSessionKeepAlive() {
      clearInterval(this.sessionKeepAlive);
      this.sessionKeepAlive = setInterval(() => {
        if (this.sessionInfo?.session_id) {
          this.repeat(this.sessionInfo?.session_id, "-");
        }
      }, 60000 * 2);
    },
    async repeatHandler() {
      if (!this.sessionInfo) {
        return;
      }

      const text = this.taskInput.trim();
      if (text === "") {
        return;
      }
      this.clearAndSetSessionKeepAlive();
      await this.repeat(this.sessionInfo.session_id, text);
    },

    async closeConnectionHandler() {
      if (!this.sessionInfo) {
        return;
      }

      this.peerConnection?.close();
      await this.stopSession(this.sessionInfo.session_id);
    },
    async newSession() {
      try {
        const response = await this.$store.dispatch("heygen/newSession", {
          quality: "high",
          avatar_id: "Tyler-insuit-20220721",
          voice: {
            voice_id:
              CONVERT_LANGUAGE_TO_VOICE_HEYGEN[this.$store.state.auth.lang],
          },
        });
        return response;
      } catch (error) {
        const errorCodeConcurrent = 10007;
        console.log("error?.code --->: ", error?.code);
        if (error?.code === errorCodeConcurrent) {
          this.$store.dispatch("auth/dispatchDialogMessage", {
            message:
              "Initial New Session: Concurrent limit reached, do you want to kick other users so you can use the service?",
            type: "confirm",
            action: async () => {
              await this.stopLastSession();
              await this.createNewSession();
            },
            reject: async () => {
             this.logout();
            },
          });
        } else {
          this.$store
            .dispatch("auth/dispatchDialogMessage", {
              message: `Initial New Session: ${error?.message}, Please LOGIN or contact developer`,
              type: "alert",
            })
            .then(() => {
              this.logout();
            });
          return null;
        }
      }
    },
    async stopLastSession() {
      try {
        const response = await this.$store.dispatch("heygen/listSession");
        const sessions = response?.sessions;

        const lastSession = sessions?.[sessions?.length - 1];
        await this.stopSession(lastSession?.session_id);
      } catch (error) {
        this.$store
          .dispatch("auth/dispatchDialogMessage", {
            message: `Stop Last Session: ${error?.message}, Please LOGIN or contact developer`,
            type: "alert",
          })
          .then(() => {
            this.logout();
          });
      }
    },
    async createTokenSession() {
      try {
        const response = await this.$store.dispatch(
          "heygen/createTokenSession"
        );
        return response;
      } catch (error) {
        return null;
      }
    },
    logout() {
      this.$store.commit("heygen/resetState");
      this.$store.dispatch("auth/logout");
    },
    async listSession() {
      try {
        const response = await this.$store.dispatch("heygen/listSession");
        const sessions = response?.sessions;

        const sessionInfo = sessions?.find(
          (session) => session.session_id === this.sessionInfo.session_id
        );
        if (!sessionInfo) {
          clearInterval(this.sessionChecker);
          throw new Error("Avatar session has expired");
        }
      } catch (error) {
        this.$store
          .dispatch("auth/dispatchDialogMessage", {
            message: `${error?.message}. Please log in again.`,
            type: "alert",
          })
          .then(() => {
            clearInterval(this.sessionKeepAlive);
            this.logout();
          });
      }
    },
    async interruptTask() {
      const response = await this.$store.dispatch("heygen/interruptTask", {
        session_id: this.sessionInfo.session_id,
      });
      return response;
    },
    async handleICE(session_id, candidate) {
      const response = await this.$store.dispatch("heygen/handleICE", {
        session_id,
        candidate,
      });
      return response;
    },
    async startSession(session_id, sdp) {
      const response = await this.$store.dispatch("heygen/startSession", {
        session_id,
        sdp,
      });
      return response;
    },
    async repeat(session_id, text) {
      try {
        const response = await this.$store.dispatch("heygen/repeat", {
          session_id,
          text,
          task_type: "repeat",
          task_mode: "sync",
        });
        this.$store.commit("heygen/setIsProcessing", false);
        // comit isSpeaking to true for the duration of the response
        this.$store.commit("heygen/setIsSpeaking", true);
        this.timeoutSpeaking = setTimeout(() => {
          this.$store.commit("heygen/setIsSpeaking", false);
        }, response.duration_ms + 1500);
        return response;
      } catch (error) {
        this.$store.dispatch("auth/dispatchDialogMessage", {
          message: `Repeat: ${error?.message}, please LOGIN again`,
          type: "alert",
        });
        this.logout();
      }
    },

    async stopSession(session_id) {
      const response = await this.$store.dispatch("heygen/stopSession", {
        session_id,
      });
      return response;
    },
    // startRendering() {
    //   // Start rendering as soon as the video is ready to play
    //   this.hideElement(this.$refs.mediaElement);
    //   this.showElement(this.$refs.canvasElement);
    //   this.renderCanvas();
    // },
    // renderCanvas() {
    //   const mediaElement = this.$refs.mediaElement;
    //   const canvasElement = this.$refs.canvasElement;

    //   this.hideElement(mediaElement);
    //   this.showElement(canvasElement);
    //   canvasElement.classList.add("show");

    //   const curRenderID = Math.trunc(Math.random() * 1000000000);
    //   this.renderID = curRenderID;

    //   const ctx = canvasElement.getContext("2d", { willReadFrequently: true });

    //   if (this.bgColor) {
    //     canvasElement.parentElement.style.background = this.bgColor.trim();
    //   }
    //   if (this.backgroundImage) {
    //     canvasElement.parentElement.style.background =
    //       this.backgroundImage.trim();
    //   }
    //   const processFrame = () => {
    //     if (curRenderID !== this.renderID) {
    //       if (this.backgroundImage) {
    //         canvasElement.parentElement.style.background = "";
    //       }
    //       return;
    //     }

    //     canvasElement.width = mediaElement.videoWidth;
    //     canvasElement.height = mediaElement.videoHeight;

    //     ctx.drawImage(
    //       mediaElement,
    //       0,
    //       0,
    //       canvasElement.width,
    //       canvasElement.height
    //     );
    //     const imageData = ctx.getImageData(
    //       0,
    //       0,
    //       canvasElement.width,
    //       canvasElement.height
    //     );
    //     const data = imageData.data;

    //     for (let i = 0; i < data.length; i += 4) {
    //       const red = data[i];
    //       const green = data[i + 1];
    //       const blue = data[i + 2];

    //       if (this.isCloseToGreen([red, green, blue])) {
    //         data[i + 3] = 0; // Set alpha to 0 for transparency
    //       }
    //     }

    //     ctx.putImageData(imageData, 0, 0);
    //     requestAnimationFrame(processFrame);
    //   };

    //   processFrame();
    // },
    // isCloseToGreen([red, green, blue]) {
    //   return green > 90 && red < 90 && blue < 90;
    // },
    // hideElement(element) {
    //   element.classList.add("hide");
    //   element.classList.remove("show");
    // },
    // showElement(element) {
    //   element.classList.add("show");
    //   element.classList.remove("hide");
    // },
  },
  watch: {
    taskInput() {
      console.log("taskInput changed");
      this.interruptTask();
      this.repeatHandler();
    },
    "$store.state.heygen.isListening": function (newVal) {
      if (!newVal) {
        clearTimeout(this.timeoutSpeaking);
      }
    },
    "$store.state.auth.lang": async function () {
      console.log("lang changed");

      this.peerConnection?.close();
      clearInterval(this.sessionChecker);
      clearInterval(this.sessionKeepAlive);
      await this.closeConnectionHandler();
      this.mediaCanPlay = false;

      // start new

      this.createNewSession();
      this.clearAndSetSessionKeepAlive();
      this.sessionChecker = setInterval(async () => {
        if (!this.sessionInfo?.session_id) {
          return;
        }
        await this.listSession();
      }, 5000);
    },
  },
  async beforeUnmount() {
    this.peerConnection?.close();
    clearInterval(this.sessionChecker);
    clearInterval(this.sessionKeepAlive);
    await this.closeConnectionHandler();
  },
};
</script>

<style scoped>
.hide {
  display: none;
}
.show {
  display: block;
}
video,
canvas {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%; /* Circular border */
  object-fit: cover; /* Ensure the video covers the area without distortion */
  overflow: hidden; /* To hide overflow if necessary */
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}
</style>
